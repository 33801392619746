.swiper-container, .home-swiper-container{
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.swiper-wrapper{
  flex:1;
  height: 50%;
  width: 100%;
  overflow-x: hidden;
}

.swiper-slide-wrap{
  aspect-ratio: 1/1;
  height: auto;
  width: 100%;
  margin: 0 auto;
  @include mq(md){
    height: 100%;
    width: auto;

  }
}

.swiper-slide{
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  .picture{
    height: 100%;
    width: 100%;
    object-fit: contain;
    display: block;
  }
  img{
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.swiper-button{
  position: absolute;
  height: calc(100% - 3em);
  width: 50%;
  z-index: 999;
  cursor: pointer;
  display: none;
  @include mq(sm){
    display: block;
  }
}

.swiper-next{
  right: 0;
}

.swiper-prev{
  left: 0;
}

.swiper-pagination{
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5em 0 1.5em;
  align-items: center;
  span{
    @extend a;
    margin: 0 0.5em;
  }
}

.swiper-pagination-bullet-active{
  color: var(--color-hover) !important;
}
.swiper-slide.text{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: scroll;
  overflow-x: hidden;
}
.text-slide-wrapper{
  margin: 0 auto;
  display: grid;
  max-width: 820px;
  grid-template-areas: "a a";
  grid-auto-columns: 50% 50%;
  p:nth-of-type(odd){
    margin-right: 0.65em;
    text-align: right;
  }
  p:nth-of-type(even){
    margin-left: 0.65em;
  }
}

.project-title{
  margin-bottom: 1em;
}

.mt{
  margin-top: 1em;
}


.swiper-container{
  .swiper-next{
    right: 0;
    cursor: pointer;
  }

  .swiper-prev{
    left: 0;
    cursor: pointer;
  }
  .swiper-slide{
    video{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}