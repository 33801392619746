// GLOBAL

*::-webkit-scrollbar{
  display: none;
}

*{
  scrollbar-width: none;
}

html,body{
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

main{
  height: 100%;
  width: 100%;
  opacity: 0;
  padding: 12.76923076923077em 0 4em;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: opacity 1s 1s 1s;
  @include mq(md){
    padding: 12.76923076923077em 0 7em;
  }
  &.no-overflow{
    overflow: hidden;
  }
  &.is-loaded{
    opacity: 1 !important;
    transition: opacity $transition-timing-default $transition-duration-default 0.5s;
  }

}

#home main{
  padding: 0;
}

.hide{
  display: none !important;
}

.show{
  display: grid;
}





// HEADER

#home header{
  background: none;
}

#home nav{
  mix-blend-mode: difference;
  li{
    color: white;
    a{
      color: white;
      &:hover{
        color: grey;
      }
    }
  }
}

.site-header{
  mix-blend-mode: difference;
  background: transparent;
  a{
    color: white;
  }
}

header{
  width: 100%;
  text-align: center;
  padding: 1.5em;
  position: fixed;
  background: white;
  z-index: 9999999;
  top:0;
  @include mq(md){
    padding: 3em;
  }
}

header a{
  color: black;
}

#home nav{
  background: none;
}

nav{
  width: 100%;
  position: fixed;
  bottom:0;
  padding: 1.5em;
  display: flex;
  background: white;
  z-index: 99999;
  justify-content: center;
  li{
    list-style-type: none;
    margin: 0 1em;
  }
  @include mq(md){
    padding: 3em;
  }
}


// HOME

.background-image{
  width: 100%;
  height: 100%;
  position: relative;
  z-index: -1;
}
.background-image picture{
  position: absolute;
  width: 100%;
  height: 100%;
}

.background-image picture img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.background-image video{
  height: 100%;
  width: 100%;
  object-fit: cover;
}



// DEFAULT PAGE

.default-page-content{
  width: 100%;
  min-height: 100%;
  padding: 0 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include mq(md){
    padding: 0 3em;
  }
}

.default-page-content .entry{
  display: grid;
  grid-template-areas: "title content";
  margin-bottom: 1em;
  /*grid-auto-columns: 8em 1fr;*/
  grid-auto-columns: 50% 50%;
  width: 100%;
  @include mq(md){
    grid-auto-columns: 1fr 1fr;
    width: 100%;
  }
  .entry-title{
    text-align: right;
    margin-right: 0.65em;
  }
  .entry-content{
    text-align: left;
    margin-left: 0.65em;
  }
  max-width: 75em;
}

#contact .default-page-content .entry{
  max-width: 70em;
}

.default-page-content .entry p{
  margin-bottom: 1em;
}


// PROJECTS PAGE

.projects-page-content{
  @extend .default-page-content;
  justify-content: flex-start;
  position: relative;
  height: 100%;
  overflow-y: scroll;
  position: relative;
}

.no-overflow .projects-page-content{
  height: 100%;
  min-height: auto;
}

.projects-menu{
  display: flex;
  position: fixed;
  background: white;
  padding-bottom: 2em;
  margin-top: -1px;
  justify-content: center;
  width: 100%;
}

.projects-menu li{
  margin: 0 0.65em;
}

.projects-container{
  width: 100%;
  flex: 1;
}

.projects-container{
  align-items: flex-start;
  justify-content: center;
  padding-top: 3em;
}

.projects p{
  color: var(--color-link) !important;
  cursor: pointer;
  span{
    display: inline;
    margin-left: 0.5em;
  }
  @include mq(md){
    span{
      display: none;
    }
  }
  &:hover{
    color: black !important;
    span{
      display: inline;
      margin-left: 0.5em;
    }
  }
}

#chronologie{
  display: grid;
  grid-template-areas: "a a";
  grid-auto-columns: 1fr 1fr;
  .year{
    margin-right: 0.65em;
    text-align: right;
  }
  .projects{
    margin-left: 0.65em;
  }
  .projects + .year{
    margin-bottom: 1em;
  }
  .projects p:last-of-type{
    margin-bottom: 1em;
  }
}

#type{
  display: grid;
  grid-template-areas: "a a";
  grid-auto-columns: 50% 50%;
  width: 100%;
  .type{
    margin-right: 0.65em;
    text-align: right;
  }
  .projects{
    margin-left: 0.65em;
  }

  .projects + .type{
    margin-bottom: 1em;
  }
  .projects p:last-of-type{
    margin-bottom: 1em;
  }
}

#echelle{
  display: grid;
  grid-template-areas: "a a";
  grid-auto-columns: 50% 50%;
  .scale{
    margin-right: 0.65em;
    text-align: right;
  }
  .projects{
    margin-left: 0.65em;
  }
  .projects + .scale{
    margin-bottom: 1em;
  }
  .projects p:last-of-type{
    margin-bottom: 1em;
  }
}